window.loaded = false;
var windowWidth = 0;

$(window).on('load', function(){
	window.loaded = true;
});

var homeSlider = undefined;
var sliderType = 'swiper';
var headerBorder = undefined;
var header = undefined;

var sliderLists = undefined;
var sliders = {};

$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	var resizeFn = function() {
		if (windowWidth != window.innerWidth) {
			windowWidth = window.innerWidth;

			$(document).trigger('pwstudio.window_width_changed', windowWidth);
		}
	}

	window.addEventListener('resize', resizeFn);

	headerBorder = $('[data-purpose="header-scroll-border"]');
	header = $('.main-header');
	if (headerBorder.length === 0) {
		headerBorder = $('.main-header');
	}

	if (headerBorder && headerBorder.length > 0) {
		var headerScrollFn = function() {
			var height = headerBorder.outerHeight(true) + headerBorder.offset().top;
			var scrollTop = $(window).scrollTop()
	  
			if (height <= scrollTop && !header.hasClass('opened-fix-menu')) {
			  header.addClass('opened-fix-menu');
			  $('body').addClass('opened-fix-menu');
			} else if (height > scrollTop && header.hasClass('opened-fix-menu')) {
			  header.removeClass('opened-fix-menu');
	  
			  $('.main-header').find('.submenu').hide(0, function() {
				var submenu = $(this);
				setTimeout(function(){
				  submenu.removeAttr('style');
				}, 250);
			  });
			}
		  };
  
	  window.addEventListener('touchend', headerScrollFn);
	  window.addEventListener('touchmove', headerScrollFn);
	  window.addEventListener('scroll', headerScrollFn);
	}

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});
	
	

	$('.remodal-open-button').click(function(){
		var target = $(this).data('popup-id');		
		if (target) {
			var item = $('[data-remodal-id="'+target+'"]');
			if (item.length == 1) {
				item.remodal().open();
			}
		}
	});
	
	$('[data-purpose="save-form-button"]').click(function(){
		var _self = this;
		var sendedForm = false;

		if (!sendedForm) {
			sendedForm = true;

			var notyf = new Notyf({
				delay:5000,
				alertIcon: 'notyf-error-icon',
                confirmIcon: 'notyf-confirm-icon'
            });

			var form = $(this.closest("form"));

			$.ajax({
				url:form.attr('action'),
				dataType:'json',
				type:'post',
				data: form.serialize(),
				beforeSend: function() {
					form.find('.has-error').removeClass('has-error');
				},
				success: function(response) {
					if (response.success) {
						if ((response.msg).trim() !== '') {
							notyf.confirm(response.msg);
						}
						setTimeout(function(){
							window.location.href = response.url;
						}, 2000);
					} else {
						if (typeof response.errors == 'object') {
							$.each(response.errors, function(key, value) {
								if (value.trim() !== '') {
									notyf.alert(value);
								}

								var input = form.find('[name="'+key+'"]');
								if (input.length > 0) {
									input.addClass('has-error');
								}
							});
						}

						setTimeout(function() {
							sendedForm = false;
						}, 500);
					}
				},
			});
		}
	});

	// if( $('.cookie-bar').length > 0 ) {

	// 	$('.cookie-bar .button').click(function(){
	// 		$('.cookie-bar').addClass('closed');

	// 		$.ajax({
	// 			url:ajax_controller+'setCookieConfirm',
	// 			dataType:'json',
	// 			type:'post',
	// 			data:{cookie:1},
	// 			success: function(){}
	// 		});
	// 	});
	// }

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		if (isNaN(timeout)) {
			timeout = 400;
		}
		
		if (item.length > 0) {
			var top = item.offset().top;
			if (header && header.length == 1) {
				top -= (header.outerHeight(true) + 170);
			}
			$('html, body').animate({scrollTop: top}, timeout);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			var companyId = $(this).data('company-id');
			
			if (companyId) {
				$('[data-purpose="local-service-id"]').val(companyId);
			}
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});

	
	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			switch(sliderType) {
				case 'bxslider':
					initHomeBxSlider();
					break;
				case 'swiper':
					initHomeSwiperSlider();
					break;
			}
		}
	}

	$('select').change(function(){
		var value = parseInt($(this).val());
		
		if (!value && $(this).hasClass('has-value')) {
			$(this).removeClass('has-value');
		} else if (value && !$(this).hasClass('has-value')) {
			$(this).addClass('has-value');
		}
	}).change();
	
	

	$('.accordion-list').each(function(ind, el) {

		var options = {};
		var th = $(el);
		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
  });
	
  	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="reference-gallery-item"]').click(function() {			
			openGallery($(this));
		});
	}

	$('[data-purpose="close-hello-bar"]').click(function(){
		closeHelloBar($(this));
	});

	sliderLists = $('[data-purpose="slider-list"]');
	if (sliderLists && sliderLists.length > 0) {
		var seq = 1;
		$.each(sliderLists, function(){

			$(this).attr('data-seq', seq);
			seq++;

			initSlider($(this));
		});

	}

	if (typeof ItemSlider !== 'undefined') {
		var sliders = $('[data-purpose="item-slider"]');
		if (sliders.length > 0) {
			sliders.each(function(){
				new ItemSlider($(this));
			});
		}
	}

	$('[data-purpose="facebook-login"]').click(function(){
		if(typeof FB != 'undefined'){
			var btn = $(this);
			btn.addClass("show-animation");
			// btn.attr('disabled', true);
			if(fb_status === 'connected'){
				loginWithFacebook(btn);
			}else{
				FB.login(function(response){
					if(response.status === 'connected'){
						loginWithFacebook(btn);
					}
				});
			}
  
		}else{
		  console.log('Nincs FB js');
		}
	});

	$('.password-show-icon').click(function(){
		$(this).toggleClass("visible");
		var input = $($(this).attr("toggle"));
		
		if (input.attr("type") == "password") {
			input.attr("type", "text");
		} else {
			input.attr("type", "password");
		}
	});

	var youtubePlay = $('[data-purpose="play-video"]');
	if (youtubePlay.length > 0) {
		youtubePlay.on('click', function(){
			$(this).find('.video-frame').show();
			this.style.background = '';
			console.log($(this));
		});
	}

	$('[data-purpose="lang-chooser"]').change(function(){
		changeFrontendLang($(this));
	});

	$('[data-purpose="openable-title-row"]').click(function(){
		var parent = $(this).parent('.openable-row');
		if (parent && parent.length === 1) {
			parent.toggleClass('opened');
			parent.find('.text-content').slideToggle(300);
		}
	});

	$('[data-purpose="stage-tag"]').click(function(){
		scrollToElement($(this).data('id'));
	});

	$('[data-purpose="stage-select"]').change(function(){
		// console.log($(this).val());
		scrollToElement($(this).val());
	});

});

function changeFrontendLang(select) {
	var option = select.find(':selected');
	if (option && option.length == 1) {
		var url = option.data('url');
		if (url.trim() !== '') {
			document.location.href = url;
		}
	}	
}

function initSlider(list) {
	var items = list.find('li');
	if (items.length > 1) {
		var touchDevice = false;
		if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
			touchDevice = true;
		}

		// sliders['sliderItem'+list.data('seq')] = list.bxSlider({
		// 	auto:true,
		// 	pause:5000,
		// 	infiniteLoop: true,
		// 	touchEnabled: touchDevice,
		// 	preventDefaultSwipeX: true,
		// 	preventDefaultSwipeY: false,
		// 	controls: false,
		// 	mode:'fade',
		// });
		var delay = parseInt(list.data('delay'));
		if (isNaN(delay)) {
			delay = 10000;
		}

		var speed = parseInt(list.data('speed'));
		if (isNaN(speed)) {
			speed = 800;
		}

		var seq = list.data('seq');

		var parent = list.parent();
		var id = parent.attr('id');
		if (!id) {
			id = 'sliderItem'+seq;
			parent.attr('id', id);
		}

		sliders['sliderItem'+seq] = new Swiper('#'+id, {
			speed: speed,
			autoplay: {
				delay:delay
			},
			pagination: {
				el:'.swiper-pagination',
				clickable: true
			},
			navigation: {
				nextEl: '.next',
				prevEl: '.prev'
			}
		});
		// sliders['sliderItem'+seq].autoplay.start();
	}
}

function loginWithFacebook(btn){
    if(typeof fb_controller != 'undefined' && fb_controller){

		var notyf = new Notyf({
			delay:5000,
			alertIcon: 'notyf-error-icon',
			confirmIcon: 'notyf-confirm-icon'
		});

        FB.api('/me', {fields:'email,last_name,first_name,id'}, function(response){
          var responseData = response;

          responseData.lang = typeof current_lang !== 'undefined' ? current_lang : '';
          responseData.current_url = document.location.href;

		  responseData.service_id = $('.login-form').find('[data-purpose="local-service-id"]').val();		  

          $.ajax({
              url:base_url+fb_controller,
              dataType:'json',
              type:'post',
              data:responseData,
              success: function(resp){
                  if(resp.success){
					if(typeof resp.url != 'undefined' && resp.url)					
						document.location.href = resp.url;
					else {
						document.location.reload();
					}
                  }else{
                    if(typeof resp.message != 'undefined' && resp.message){                      
						notyf.alert(resp.message);					
                    }

                      setTimeout(function(){
                          btn.attr('disabled', false);
                      }, 500);

                  }
              }
            });
        });
    }
}

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}
		
		gtag('event', action, evtData);
	} else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(device == 'iphone' || device == 'ipad' || device == 'ipod'){
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}

function initHomeBxSlider() {
	var touchDevice = false;
	  if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
	  	touchDevice = true;
	  }
  
	  homeSlider = $('#homeSlider').bxSlider({
		  auto:true,
		  pause:5000,
		  infiniteLoop: true,
		  touchEnabled: touchDevice,
		  preventDefaultSwipeX: true,
		  preventDefaultSwipeY: false,
		  controls: false,
		  mode:'fade',
		  speed: 1000,
		pager:false,
	  });
	  
	  if (typeof LazyLoad !== 'undefined') {
		  var list = document.getElementById('homeSlider');
		  var items = list.getElementsByTagName('li');
		  for (var i = 0; i < items.length; i++) {
			  LazyLoad.load(items[i]);
		  }
	  }
  }

function initHomeSwiperSlider()
{
	homeSlider = new Swiper('#homeSlider', {
		effect: 'fade',
		autoplay: {
			delay:20000
		},
		pagination: {
			el:'.swiper-pagination',
			clickable: true
		}
	});
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');
	
	if (typeof mediaGroupItems === 'object' && typeof mediaGroupItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, mediaGroupItems[groupId], index);
	} 
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) 
{
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic:true,
		dynamicEl:items,
		index:index,
		hash:false,
		download: false
	});
}

function initGalleries()
{
	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="gallery-box-item"]:not(.initialized)').each(function(){
			$(this).addClass('initialized');
			$(this).click(function(){
				var id = $(this).data('group-id');
				if (id && typeof mediaGroupItems[id] !== 'undefined') {
					initGallery($(this), mediaGroupItems[id]);
				}
			});
		});
	}
}

function closeHelloBar(btn)
{
	var parent = btn.parent();
	if (parent && !parent.hasClass('closed')) {
		parent.addClass('closed');
		parent.slideToggle();

		$.ajax({
			url:ajax_controller+'closeHelloBar',
			dataType:'json',
			type:'post'
		});
	}
}

function scrollToElement(item_id){
    if(item_id==='body')
        $('html,body').animate({scrollTop: $('body').position().top}, 1000);
    else{
        $('html,body').animate({scrollTop: $('#'+item_id).position().top}, 1000);
    }
}