var CookieHandler = function() {
    this.init();
}

CookieHandler.prototype = {
    groups: {},
    options: {
        reloadOnSave: true,
    },
    init:function() {
        console.log('handler');
    },
    handleCookieSave: function(response) {
        this.groups = response;
        if (this.getOption('reloadOnSave')) {
            document.location.reload();
        } else {
            
        }
    },
    getOption: function(key) {
        return typeof this.options[key] !== 'undefined' ? this.options[key] : null;
    }
}