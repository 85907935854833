(function(wnd){
    function MapboxMap(container, params) {
        try {
            if (!container || (container && container.length == 0)) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    MapboxMap.prototype = {
        container: undefined,             
        coordinates: {},
        center_lng: undefined,
        center_lat: undefined,
        markers: undefined,
        map: undefined,
        accessToken: undefined,
        zoom: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
            this.initMap();
        },
        setElements: function() {
        },
        addDOMEvents: function() {
            var _self = this;
            
        },
        setParams: function(params) {
            if(params.style) {this.style = params.style;}
            if(params.accessToken) {this.accessToken = params.accessToken;}
            if(params.center_lng) {this.center_lng = params.center_lng;}
            if(params.center_lat) {this.center_lat = params.center_lat;}
            if(params.markers) {this.markers = params.markers;}
            if(params.zoom) {this.zoom = params.zoom;}
        },
        initMap: function() {
            if (typeof mapboxgl !== 'undefined' && mapboxgl.supported()) {
                var _self = this;
                
                var center = (this.center_lng && this.center_lat) ? [this.center_lng,this.center_lat] : [21.626754,47.530482];                            

                mapboxgl.accessToken = _self.accessToken;
                this.map = new mapboxgl.Map({
                    container: 'mapContent',
                    style: _self.style,
                    zoom:_self.zoom ? _self.zoom : 12,
                    center: center
                });

                this.map.scrollZoom.disable();

                if (this.markers) {
                    
                    var min_lng = 100;
                    var max_lng = 0;
                    var min_lat = 100;
                    var max_lat = 0;

                    var counter = 0;

                    $.each(this.markers, function(key, value){
                        if (value.marker_lng && value.marker_lat) {
                            counter++;

                            var el = document.createElement('div');
                            el.className = 'map-marker '+key+'-marker';
                            el.innerHTML = svg('marker');                        

                            new mapboxgl.Marker(el).setLngLat([value.marker_lng, value.marker_lat]).addTo(_self.map);                        

                                var lat = parseFloat(value.marker_lat);
                                var lng = parseFloat(value.marker_lng);
                                
                                if (lng < min_lng) {
                                    min_lng = lng;
                                }

                                if (lng > max_lng) {
                                    max_lng = lng;
                                }

                                if (lat < min_lat) {
                                    min_lat = lat;
                                }

                                if (lat > max_lat) {
                                    max_lat = lat;
                                }
                            }
                    });

                    if (counter > 1) {
                        _self.map.fitBounds([[min_lng, min_lat], [max_lng, max_lat]], {
                            padding:30
                        });                    
                    }

                }
            } 
        }
    };

    wnd.MapboxMap = MapboxMap;
})(window);
